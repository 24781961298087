import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
// import FlowConfigurator from '@cloudpivot-hermes/flow-configurator';
// import { AppAuthService } from '@/sevices';
let AuthConfig = class AuthConfig extends Vue {
    get appName() {
        return this.$route.query.appName;
    }
    backHome() {
        this.$router.push({
            name: 'app-installed',
        });
    }
    backDetail() {
        this.$router.push({
            name: 'app-detail',
            query: {
                appName: this.appName,
            },
        });
    }
};
AuthConfig = __decorate([
    Component({
        name: 'AuthConfig',
        components: {
            AppAuthList: () => import('./auth-list'),
        },
    })
], AuthConfig);
export default AuthConfig;
